import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, take, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

const apiURLs = {
  root: environment.apiURL,
  userInfo: '/client/profile/',
  changePassword: '/user/password/set/'
};

export interface UserData {
  email: string;
  last_name: string;
  first_name: string;
  mobile_phone: string;
  company_name: string;
  company_size: number;
}

export interface ChangePasswordData {
  old_password: string;
  password1: string;
  password2: string;
}

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  private userData: BehaviorSubject<UserData | null> = new BehaviorSubject<UserData | null>(null);

  constructor(
    private http: HttpClient,
  ) {
    this.getUserInfoFromApi();
  }

  getUserInfoFromApi(): void {
    this.http.get(apiURLs.root + apiURLs.userInfo).pipe(take(1)).subscribe({
      next: (res: any) => {
        console.log('USER DATAAA', res.data);
        this.userData.next(res.data);
      },
      error: err => {
        console.log(err)
      }
    })
  }

  getUserInfo() {
    return this.userData.asObservable();
  }

  updateUserInfoAfterEdit(data: UserData) {
    this.userData.next(data);
  }

  editUserInfo(data: UserData): Observable<any> {
    return this.http.put(apiURLs.root + apiURLs.userInfo, data);
  }

  changePassword(data: ChangePasswordData): Observable<any> {
    return this.http.put(apiURLs.root + apiURLs.changePassword, data);
  }
  
}
